import Request from "@/utils/request";

let API = {
  //一级分类
  C1_URL: "/v1/menu/list",
};

// 一级分类接口方法
export const reqGetMenuList = () => {
    return Request.get(API.C1_URL);
};