<template>
        <i class="xiaoxiang-star"/>
</template>

<script setup>

</script>

<style lang="scss" scoped>
 .xiaoxiang-star {
            width: 14px;
            height: 14px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABYUlEQVQ4T73Tu0pDQRAG4P//95xjIhZa2FsErIKlFjaW2ngFDUZQbHwDBZs0glaKjREUr6iFPkOaFDYWkkpI4RskXk+QJEeyqHiJxxu43cLMx+zMLPHHw7B8fy9yCBHRcT/xWdynwMNuZLpCbNAQMsGkN1raqYfUBfw1tLHRywVSUw2AeBsE1Xg0Ubp8j3wAHjbdjgqRhtQFQzwBoHRKJ5jxhu7OXyMWKK2gPfCUhMEYqFgtEUZ4DdTuFjPMSzpynMo+e28uLHC/igIMm22QbFAYAMhiRa+v0PIMzMJw8SeADOfcvsKSBTIpOJ2tzMCo+zsVUMq6Z8UeplB+aaK/jDZETQ5iU/gTdFutMh4dLNqJvJmCnzZ7EJNf9GC/of964nkS74EtiJOhgLjdMHA99T8ADfNwCJCxlz0IrWBd85BmKB5XEZw0Tpezdk8OIt2O6wwHhiOS0u7A1ULdJ/zmZz8CcgxiEeQBXJ8AAAAASUVORK5CYII=) no-repeat 50%;
            background-size: auto;
            background-size: contain;
            display: inline-block;
        }
</style>