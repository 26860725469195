<template>
  <footer class="footer">

    <!-- 未完成 -->
    <div style="position:relative;" class="footer-main">
      <div class="main" v-for="item in aboutUsList" :key="item.sortId">
        <h4>{{ item.sortName }}</h4>
        <router-link :to="{
          name: 'about',
          query: {
            aboutId: child.sortId
          }
        }" class="footer-main-link" v-for="child in item.children" :key="child.sortId">{{ child.sortName }}</router-link>
      </div>

      <div>
        <img src="https://kuaixiaobang-1320991193.cos.ap-nanjing.myqcloud.com/2024/08/08/db3592db-cfbd-4bfb-bb52-b0cf7db199cf.png" style="width: 150px;
                    position: absolute;
                    right: 150px;
                    top: -25px;
                    z-index: 9999;">
        <span style="position: absolute;top:140px;color:#fff;right: 110px;font-size:12px;">工作时间：周一至周五 9：00-18：00 单双休</span>
        <span style="position:absolute;top:170px;color:#ffd102;right: 158px;font-size:24px;">19352781070</span>
      </div>
    </div>

    <div class="copyright">
      <div class="container">
        <div class="node_text">
          <span>Copyright © 2023 - 2023 quanzhiol.com. All Rights Reserved. 快小帮 版权所有</span>
          <span><a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备2023032996号 苏公网安备32131102000701号</a></span>
        </div>
      </div>
    </div>

  </footer>
</template>

<script setup>
import { ref } from "vue";
import {reqGetAboutUsList} from "@/apis/aboutUs";
import {handleTree} from "@/utils/kuai";
let aboutUsList = ref([])

reqGetAboutUsList().then(res => {
  aboutUsList.value = handleTree(res.data, "sortId", "parentId");
})


</script>

<style lang="scss" scoped>
.footer>ul>li>ul li:first-of-type {
  font-size: 22px;
  margin-bottom: 20px;
}

// 
.footer {
  background-color: black;
  box-sizing: border-box;
  padding: 80px 64px 0px;

  .footer-main {
    width: 1320px;
    margin: 0 auto;
    display: flex;

    .main {

      font-size: 0;
      display: inline-block;
      vertical-align: top;
      margin-right: 130px;
    }

    h4 {
      font-size: 22px;
      line-height: 1;
      margin: 0 0 15px;
      font-weight: 400;
      color: white;
    }

    .footer-main-link {
      display: block;
      margin: 0;
      line-height: 2;
      font-size: 16px;
      color: #9b9b9b;
    }
  }

  .copyright {
    display: flex;
    width: 1320px;
    margin: 0 auto;
    margin-top: 80px;
    border-top: 1px solid #dddddd29;
    justify-content: center;

    .container {
      padding: 30px 0;
      color: #9b9b9b;
      font-size: 16px;

      a {
        margin-left: 8px;
        color: #9b9b9b;
      }
    }
  }
}
</style>