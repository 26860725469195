//统一管理用户相关的接口
import request from "@/utils/request";
//统一接口管理
let API = {
  captchaImage_URl:"/captchaImage",
  LOGIN_URL: "/login",
  USERINFO_URL: "/getInfo",
  LOGOUT_URL: "/logout",
  REGISTER_URL: "/register",
};
//暴露请求函数
//获取验证码图片
export const reqImage = () => request.get(API.captchaImage_URl);
//暴露接口方法
export const reqLogin = (data) => request.post(API.LOGIN_URL, data);
//获取用户信息接口方法
export const reqUserInfo = () => request.get(API.USERINFO_URL);
//退出登录
export const reqLogOut = () => request.post(API.LOGOUT_URL);
export const registerUser = (user) => request.post(API.REGISTER_URL, user);
