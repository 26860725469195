<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script setup>
import { REMOVEITEM_TOKEN, GET_TOKEN, SET_TOKEN } from "@/utils/token";
import {onMounted} from "vue";
import {useUserStore} from "@/store/modules/user";
import {useRouter} from "vue-router";
let userStore = useUserStore();
let router = useRouter()

onMounted(async ()=>{
  if(GET_TOKEN()){
    try {
      await userStore.userInfo();
    } catch (error) {
      // token过期
      await userStore.logout();
      await router.push({path: "/login"})
    }
  }
})
if (process.env.NODE_ENV === 'production') {
  console.log = function() {};
  // 可以根据需要重写其他console方法，如console.error, console.warn等
}
</script>

<style>

</style>
