<template>
  <div class="searchMian">
    <div class="childMainBox">
      <div class="boxLeft">
        <el-link :underline="false" @click="gorouter('/hireWorker')"
        >雇佣员工
        </el-link
        >
        <el-link :underline="false" @click="gorouter('/WorkCase')"
        >作品案例
        </el-link
        >
        <el-link :underline="false" @click="gorouter('/release')"
        >发布招聘
        </el-link
        >
      </div>
      <div class="boxRight">
        <div class="select">
          <el-select
              v-model="value"
              placeholder="设计"
              size="large"
              style="width: 102px"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
        <div class="input">
          <el-input
              v-model="input"
              style="width: 300px"
              placeholder="请选择关键字"
              clearable
              size="large"
          >
            <template #suffix>
              <el-icon class="el-input__icon">
                <calendar/>
              </el-icon>
            </template>
          </el-input>
        </div>
        <div class="select footer">
          <el-button class="button" @click="query">搜索</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const $route = useRouter();
const gorouter = (url) => {
  $route.push({path: url});
};

const input = ref("");
const value = ref(18);
let router = useRouter();
const options = [
  {
    value: 0,
    label: "作品",
  },{
    value: 18,
    label: "设计",
  },
  {
    value: 19,
    label: "摄影",
  },
  {
    value: 20,
    label: "客服",
  },
  {
    value: 21,
    label: "视频",
  },
];

function query() {
  if(value.value === 0) {
    router.push({
      name: "WorkCase",
      query: {
        worksName: input.value,
      },
    });
  } else {
    router.push({
      name: "hireWorker",
      query: {
        menuId: value.value,
        nickName: input.value,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.searchMian {
  background-color: #fff;
  width: 100%;
  height: 90px;

  .childMainBox {
    height: 100%;
    width: calc(1.5 * 880px);
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;

    .boxLeft {
      .el-link {
        margin-right: 40px;
      }
    }

    //position: absolute;
    //right: 0;
    //top: 50%;
    //transform: translateY(-50%);
    .boxRight {
      width: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      overflow: hidden;
      border: 1px solid #ffcf07;
      border-radius: 18px;

      .footer {
        .button {
          width: 105px;
          height: 50px;
          background-color: #ffcf07;
        }
      }

      .input {
        border: none;
        box-shadow: none;
      }

      .select {
        width: 100px;
        text-align: center;
        font-size: 18px;

        .el-select .el-input {
          border: none;
          box-shadow: none;
        }

        /*去除边框*/
        :deep(.el-select .el-input__wrapper) {
          box-shadow: none !important;
        }

        // *去除选中时蓝色边框（下面两个都要加上） */
        :deep(.el-input .el-input__wrapper.is-focus) {
          box-shadow: none !important;
        }

        :deep(.el-select .el-input.is-focus .el-input__wrapper) {
          border-color: #dcdfe6 !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
</style>