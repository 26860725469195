//封装本地存储存储数据与读取数据方法

//本地存储存储数据
export const SET_TOKEN = (token) => {
  localStorage.setItem("kuaixiaobang-Token", token);
};
//本地存储获取数据

export const GET_TOKEN = () => {
  return localStorage.getItem("kuaixiaobang-Token");
};

export const REMOVEITEM_TOKEN = () => {
  return localStorage.removeItem("kuaixiaobang-Token");
};
