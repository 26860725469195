import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import '@/assets/styles/index.scss'
import 'aos/dist/aos.css'; // 引入AOS的CSS样式文件  
import AOS from 'aos'; // 引入AOS库 
import './permisstion'; //导航守卫
//全局方法
import {handleTree} from '@/utils/kuai';

//全局组件
import MyStar from '@/components/XiaoxiangStar' //星星全局组件
import HeaderPage from "@/components/Header";
import SearchPage from "@/components/Search";
import FooterPage from "@/components/Footer";

const app = createApp(App)
//全局方法
// 树形结构
app.config.globalProperties.$handleTree  = handleTree;
//全局组件挂载
app.component('MyStar',MyStar);
app.component('HeaderPage',HeaderPage);
app.component('SearchPage',SearchPage);
app.component('FooterPage',FooterPage);
// 导pinia
// 创建pinia对象
let pinia= createPinia()
// 初始化AOS库  
AOS.init();  
app.use(pinia)
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(router)
app.mount('#app')
