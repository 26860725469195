import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "IndexPage",
      component: () => import("@/view/indexPage/index.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/login/index.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/view/register/index.vue"),
    },
    {
      path: "/hireWorker",//雇佣
      name: "hireWorker",
      component: () => import("@/view/hireWorker/index.vue"),
    },
    {
      path: "/WorkCase",//作品
      name: "WorkCase",
      component: () => import("@/view/WorkCasePage/index.vue"),
    },
    {
      path: "/WorkCase/detail/:worksId",//作品
      name: "WorkCaseDetail",
      component: () => import("@/view/WorkCasePage/detail.vue"),
    },
    {
      path: "/hireWorker/detail/:staffId",//作品
      name: "HireWorkerDetail",
      component: () => import("@/view/hireWorker/staffDetail.vue"),
    },
    {
      path: "/hireWorker/placeOrder/:staffId",//作品
      name: "PlaceOrder",
      component: () => import("@/view/payPage/index.vue"),
    },
    {
      path: "/release",//发布
      name: "release",
      component: () => import("@/view/releasePage/index.vue"),
    },
    {
      path: "/about",//发布
      name: "about",
      component: () => import("@/view/aboutUs/index.vue"),
    },
    {
      path: "/404",
      component: () => import("@/view/404/index.vue"),
      name: "404", //命名路由
      meta: {
        title: "404",
        hidden: true, //路由在页面是否隐藏
        icon: "Tools",
      },
    },
    {
      //任意路由
      path: "/:pathMatch(.*)*",
      // 从定向
      redirect: "/404",
      name: "Any",
      meta: {
        title: "任意路由",
        hidden: true, //路由在页面是否隐藏
        icon: "Tools",
      },
    },
  ],
});

export default router;
