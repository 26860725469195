<template>
  <div class="headerLayout">
    <div class="header-main">
      <el-menu
          default-active="1"
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          background-color="#ffd102"
          @select="handleSelect"
          text-color="#5c3c00"
          active-text-color="#000"
          style="max-width: 2000px"
      >
        <div style="padding: 0; margin-right: 50px">
          <router-link :to="'/'">
            <img
                class="logo"
                src="https://kuaixiaobang-1320991193.cos.ap-nanjing.myqcloud.com/2024/08/02/7617dd78-1345-447d-beab-8781f7f80193.png"
                alt="logo"
            />
          </router-link>
        </div>
        <template v-for="(item, index) in dataList" :key="item.menuId">
          <el-menu-item :index="item.menuId.toString()" v-if="!item.children">
            <span class="text">{{ item.menuName }}</span>
          </el-menu-item>

          <el-sub-menu :index="item.menuId.toString()" v-else>
            <template #title>
              <span class="text">{{ item.menuName }}</span>
            </template>
            <el-menu-item
                v-for="(Citem, index1) in item.children"
                :index="`${item.menuId}-${Citem.menuId}`"
                :key="Citem.menuId"
            >
              {{ Citem.menuName }}
            </el-menu-item>
          </el-sub-menu>
        </template>

        <div class="flex-grow"/>

        <div class="class" v-if="userStore.userName">
          <el-avatar
              class="mr-3"
              :size="52"
              :src="userStore.avatar"
          />
          <el-dropdown size="large" style="margin-left: 10px">
            <span class="el-dropdown-link">
              {{ userStore.userName }}
              <el-icon class="el-icon--right"><arrow-down/></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="a" @click="toAdmin">管理后台</el-dropdown-item>
                <el-dropdown-item command="b" @click="logout">退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>

        <div class="class" v-else>
          <el-button type="warning" round @click="$route.push('/login')">
            登陆
          </el-button>
          <el-button type="warning" round @click="$route.push('/register')">
            注册
          </el-button>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {reqGetMenuList} from "@/apis/basicsPage/menu";
import {handleTree} from "@/utils/kuai";
import {ArrowDown} from "@element-plus/icons-vue";
import {useRouter} from 'vue-router';

//用户相关的数据
import {useUserStore} from "@/store/modules/user";

let userStore = useUserStore();

const $route = useRouter()


const dataList = ref([]);
//获取菜单信息
const getMenuList = async () => {

  try {
    const res = await reqGetMenuList(); // 等待异步请求完成
    dataList.value = handleTree(res.data, "menuId", "menuParentId"); // 将结果赋值给 dataList
    // console.log(dataList.value);
  } catch (error) {
    console.error("获取菜单列表时出错:", error);
  }

};
getMenuList();

//菜单信息数据化
const handleSelect = function (key, keyPath) {
  console.log("菜单", keyPath)
  if (keyPath[0] == 1) {
    $route.push("/")
    return;
  }
  $route.push({
    name: "hireWorker",
    query: {
      menuId: keyPath,
    },
  });
  // console.log(key, keyPath[0])
};

//退出登录
const logout = () => {
  userStore.logout();
}

function toAdmin(){
  // $route.push("/admin")
  window.open("/admin")
}
</script>

<style lang="scss" scoped>
.flex-grow {
  flex-grow: 1;
}

 //关闭页面背景颜色
 .el-menu-item .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
   background-color: rgba(0, 0, 0, 0);
 }
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  color: var(--el-menu-active-color)!important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: rgba(0, 0, 0, 0.09);
  color: var(--el-menu-hover-text-color);
  outline: none;
}

//关闭页面背景颜色

.headerLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color;

  .header-main {
    width: calc(1.5 * 880px);

    .el-menu-popper-demo,
    .el-menu-demo {
      height: 81px;

      .class {
        margin: auto 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-button {
          width: 100px;
          height: 33px;
        }
      }

      .text {
        font-size: 16px;
      }

      .logo {
        width: 140px;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>