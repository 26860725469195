//路由鉴权
import router from "@/router";
import seting from "./seting";
//存放token
// import { useUserStore } from "@/store/modules/user";
// import pinia from "@/store";
//全局前置守卫
/**
 * to ：你要访问哪个路由
 * from: 你从哪个路由来
 * next :放行
 *
 */
// let userStore = useUserStore(pinia);

// router.beforeEach(async (to, from, next) => {
//   //  获取token
//   let token = userStore.token;
//   let userName = userStore.userName;
//   next()
//   if (token) {
//     if (to.path == "/login") {
//       next({ path: "/" });
//     } else {
//       if (userName) {
//         next();
//       } else {
//         try {
//           await userStore.userInfo();
//           next();
//         } catch (error) {
//           // token过期
//           await userStore.logout();
//           next({ path: "/login", query: { redirect: to.path } });
//         }
//       }
//     }
//     //未登录
//   }
//   else {
//     if (to.path == "/login" || to.path == "/") {
//       next();
//     } else {
//       next({ path: "/login", query: { redirect: to.path } });
//     }
//   }




  // if (token) {
  //   //登录成功想去login不行
  //   if (to.path == "/login") {
  //     next({ path: "/" });
  //   } else {
  //     if (username) {
  //       next();
  //     } else {
  //       //没有用户信息就发请求
  //       try {
  //         await userStore.userInfo();
  //         next();
  //       } catch (error) {
  //         //token过期
  //         await userStore.logout();
  //         next({ path: "/login", query: { redirect: to.path } });
  //       }
  //     }
  //   }
  // } else {
  //   // 用户未登录
  //   if (to.path == "/login" || to.path == "/") {
  //     next();
  //   } else {
  //     next({ path: "/login", query: { redirect: to.path } });
  //   }
  // }
// });

// router.beforeEach(async (to, from, next) => {
//   // 获取token和userName
//   const token = userStore.token;
//   const userName = userStore.userName;
//
//   // 如果已经登录（有token）
//   if (token) {
//     // 如果访问的是登录页面，则重定向到首页
//     if (to.path === "/login") {
//       next({ path: "/" });
//     } else {
//       // 如果没有用户名，尝试从后端获取用户信息
//       if (!userName) {
//         try {
//           await userStore.userInfo(); // 假设这个方法会更新userName或抛出异常
//           // 如果成功获取用户信息（即没有抛出异常），则继续导航
//           next();
//         } catch (error) {
//           // 如果获取用户信息失败（例如token过期），则登出并重定向到登录页面
//           await userStore.logout();
//           next({ path: "/login", query: { redirect: to.fullPath } }); // 使用fullPath保留完整的URL
//         }
//       } else {
//         // 如果有用户名，则直接继续导航
//         next();
//       }
//     }
//   } else {
//     // 如果没有登录（无token）
//     // 如果访问的是登录页面或首页，则允许访问
//     if (to.path === "/login" || to.path === "/") {
//       next();
//     } else {
//       // 否则重定向到登录页面，并带上当前页面的路径作为查询参数
//       next({ path: "/login", query: { redirect: to.fullPath } });
//     }
//   }
// });

//全局后置守卫
router.afterEach((to, from) => {
  document.title = `${seting.title}`;
});
//路由鉴权
