//进行axios二次封装：使用请求响应拦截器
import axios from "axios";
import {ElMessage, ElMessageBox, ElNotification} from "element-plus";
//引入用户信息
import {useUserStore} from "@/store/modules/user";
import errorCode from "@/utils/errorCode";
import router from '@/router';

// 是否显示重新登录
export let isRelogin = {show: false};
//第一步;利用axios对象的create方法,去创建axios实例(其他的配置:基础路径、超时的时间
let request = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000, //超时时间
});

//第二步:request实例添加请求拦截器
request.interceptors.request.use((config) => {
    //获取token
    let userStore = useUserStore();

    if (userStore.token) {
        //将tokne加在请求头
        config.headers.Authorization = userStore.token;
    }
    //config配置对象,headers属性请求头，经常给服务器端携带公共参数
    //返回配置对象

    return config;
});
//第三步:响应拦截器
request.interceptors.response.use(res => {
    let userStore = useUserStore();

    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
        return res.data
    }
    if (code === 401) {
        ElNotification({
            type: "error",
            message: msg,
            duration: 2000
        })

        userStore.logout().then(r => {});
        router.push({name: "login"}).then(r => {})

        return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
        if (msg === "获取用户信息异常") {
            ElNotification({
                type: "error",
                message: "请先登录后再下单！",
                duration: 2000
            })
            router.push({name: "login"}).then(r => {})
        } else {
            ElNotification({message: msg, type: 'error'})
            return Promise.reject(new Error(msg))
        }
    } else if (code === 601) {
        ElNotification({message: msg, type: 'warning'})
        return Promise.reject('error')
    } else if (code !== 200) {
        Notification.error({title: msg})
        return Promise.reject('error')
    } else {
        return res.data
    }
}, error => {
    console.log('err' + error)
    let {message} = error;
    if (message == "Network Error") {
        message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    ElNotification({message: message, type: 'error', duration: 5 * 1000})
    return Promise.reject(error)
})

export default request;
