import {defineStore} from "pinia";
import {ElNotification} from "element-plus";
import {reqLogin, reqUserInfo, reqLogOut} from "@/apis/user";
import {REMOVEITEM_TOKEN, GET_TOKEN, SET_TOKEN} from "@/utils/token";
//定义数据并且对外暴露
// store就是定义共享状态的包装对象
// 内部包含四个属性： id 唯一标识 state 完整类型推理，推荐使用箭头函数 存放的数据 getters 类似属性计算，存储放对数据
// 操作的方法  actions 存储数据的复杂业务逻辑方法
// 理解： store类似Java中的实体类， id就是类名， state 就是装数据值的属性  getters就是get方法，actions就是对数据操作的其他方法
export const useUserStore = defineStore("useUserStore", {
    state: () => {
        // state中用于定义数据
        return {
            token: GET_TOKEN() || "",
            userName: '',//用户名字
            avatar: '',//头像
        }
    },
    actions: {
        // 用于定义一些对数据修改的方法
        //用户登陆的方法
        async userLogin(loginForm) {
            let resulet = await reqLogin(loginForm);
            console.log(resulet);
            if (resulet.code === 200) {
                this.token = resulet.token;
                SET_TOKEN(resulet.token)
                await this.userInfo();
                return resulet.msg;
            } else {
                return Promise.reject(new Error(resulet.msg));
            }
        },
        async userInfo() {
            try {
                let resulet = await reqUserInfo();
                //用户有就存储
                if (resulet.code === 200) {
                    console.log("登录用户：", resulet)
                    this.userName = resulet.user.nickName;
                    this.avatar = resulet.user.avatar;
                    return resulet.msg;
                } else {
                    return Promise.reject(new Error(resulet.msg));
                }
            } catch (e) {
                // ElNotification({
                //   type: "error",
                //   message: e.message,
                //   duration: 2000
                // })
            }
        },
        async logout() {
            let result = await reqLogOut();
            if (result.code === 200) {
                this.token = "";
                this.userName = "";
                this.avatar = "";
                console.log("TOKEN", GET_TOKEN())
                if(GET_TOKEN()) {
                    ElNotification({message: "退出登录成功~", type: "success"})
                }
                REMOVEITEM_TOKEN();
            }
        }
    },
    getters: {
        // 用于定义一些通过数据计算而得到结果的一些方法 一般在此处不做对数据的修改操作
        // getters中的方法可以当做属性值方式使用
    },
});
